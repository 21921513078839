import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBHAZDqDslXe8gEloXLqv1hPDKhkGuQYVw",
  authDomain: "nfc-connect-4addd.firebaseapp.com",
  projectId: "nfc-connect-4addd",
  storageBucket: "nfc-connect-4addd.appspot.com",
  messagingSenderId: "638797925669",
  appId: "1:638797925669:web:77496880da204acb7e1ecc",
  measurementId: "G-PG1SVGKQBP"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

// if (window.location.hostname === 'localhost') {
//     connectFunctionsEmulator(functions, 'localhost', 5001)
// }