import { useEffect, useState } from "react";

import ecLogo from "./assets/EC-LogoFullColor.svg";
import { ReactComponent as PalmDesign } from "./assets/palm.svg";
import { ReactComponent as CrossDesign } from "./assets/cross.svg";
import { ReactComponent as CrownDesign } from "./assets/crown.svg";
import { ReactComponent as Portrait1 } from "./assets/4x5.svg";
import { ReactComponent as Portrait2 } from "./assets/9x16.svg";
import { ReactComponent as DownloadDesign } from "./assets/download.svg";
import { useParams } from "react-router-dom";

function Home() {
  const { campus } = useParams();
  const [experience, setExperience] = useState("easter");
  const [design, setDesign] = useState("4x5");
  const [blob, setBlob] = useState();

  // Check window size to determine if user is on mobile device
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (campus && experience && design) {
      const imageUrl = `https://storage.googleapis.com/nfc-connect-4addd.appspot.com/easterImages/${campus}/${experience}_${design}.jpg`;
      fetch(imageUrl)
        .then((response) => {
          return response.blob();
        })
        .then((newBlob) => {
          setBlob(newBlob);
        });
    }
  }, [campus, experience, design]);

  const handleDownload = async () => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `${experience}_${design}.jpg`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  return (
    <div className="Home">
      <div className="w-screen h-screen flex flex-col justify-start items-center">
        <div className="w-full pt-5 px-5 flex flex-row justify-start items-center">
          <a
            className="w-fit h-fit block"
            href="https://elevationchurch.org/"
            target="_blank"
            rel="noreferrer"
          >
            <img className="w-20" src={ecLogo} alt="Elevation Church" />
          </a>
        </div>
        <div className="w-full h-fit py-5">
          <div className="w-full h-[0.15rem] bg-[#F2F2F2]" />
        </div>
        <div className="w-full h-fit lg:w-[75%] xl:w-[60%] pb-8 px-5 md:px-16 grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 gap-5">
          {isMobile && (
            <img
              className={`max-h-[40vh] justify-self-center 2xl:col-span-3 mb-3 md:mb-0 ${
                design === "4x5" ? "aspect-4/5" : "asepct-9/16"
              }`}
              src={`https://storage.googleapis.com/nfc-connect-4addd.appspot.com/easterImages/${campus}/${experience}_${design}.jpg`}
              alt={`${experience}_${design}`}
            />
          )}
          <div className="w-full h-fit flex flex-col justify-between items-start gap-5">
            <h1 className="text-black font-black text-2xl sm:text-3xl md:text-3xl xl:text-3xl tracking-wide mb-2">
              Easter at Elevation
            </h1>
            <h1 className="text-black font-semibold text-xl sm:text-2xl md:text-2xl xl:text-2xl text-center">
              Experience
            </h1>
            <div className="w-full flex flex-col justify-center items-center gap-5 mb-5">
              <button
                className={`w-full h-14 flex justify-center items-center rounded-2xl ${
                  experience === "palm_sunday"
                    ? "bg-[#FF671F]"
                    : "bg-[#EEEEEE] hover:outline hover:outline-2 hover:outline-[#FF671F]"
                }`}
                onClick={() => {
                  setExperience("palm_sunday");
                }}
              >
                <div className="w-fit flex flex-row justify-center items-center gap-3">
                  <PalmDesign
                    className={`h-[2rem] w-auto ${
                      experience === "palm_sunday" ? "fill-white" : "fill-black"
                    }`}
                  />
                  <h3
                    className={`font-medium text-lg sm:text-xl md:text-xl xl:text-xl text-center ${
                      experience === "palm_sunday" ? "text-white" : "text-black"
                    }`}
                  >
                    Palm Sunday
                  </h3>
                </div>
              </button>
              <button
                className={`w-full h-14 flex justify-center items-center rounded-2xl ${
                  experience === "good_friday"
                    ? "bg-[#FF671F]"
                    : "bg-[#EEEEEE] hover:outline hover:outline-2 hover:outline-[#FF671F]"
                }`}
                onClick={() => {
                  setExperience("good_friday");
                }}
              >
                <div className="w-fit flex flex-row justify-center items-center gap-3">
                  <CrossDesign
                    className={`h-[2rem] w-auto ${
                      experience === "good_friday" ? "fill-white" : "fill-black"
                    }`}
                  />
                  <h3
                    className={`font-medium text-lg sm:text-xl md:text-xl xl:text-xl text-center ${
                      experience === "good_friday" ? "text-white" : "text-black"
                    }`}
                  >
                    Good Friday
                  </h3>
                </div>
              </button>
              <button
                className={`w-full h-14 flex justify-center items-center rounded-2xl ${
                  experience === "easter"
                    ? "bg-[#FF671F]"
                    : "bg-[#EEEEEE] hover:outline hover:outline-2 hover:outline-[#FF671F]"
                }`}
                onClick={() => {
                  setExperience("easter");
                }}
              >
                <div className="w-fit flex flex-row justify-center items-center gap-3">
                  <CrownDesign
                    className={`h-[2rem] w-auto ${
                      experience === "easter" ? "fill-white" : "fill-black"
                    }`}
                  />
                  <h3
                    className={`font-medium text-lg sm:text-xl md:text-xl xl:text-xl text-center ${
                      experience === "easter" ? "text-white" : "text-black"
                    }`}
                  >
                    Easter
                  </h3>
                </div>
              </button>
            </div>
            <h1 className="text-black font-bold text-xl sm:text-2xl md:text-2xl xl:text-2xl text-center">
              Design
            </h1>
            <div className="w-full grid grid-cols-2 gap-5">
              <button
                className={`w-full h-14 flex justify-center items-center rounded-2xl ${
                  design === "4x5"
                    ? "bg-[#FF671F]"
                    : "bg-[#EEEEEE] hover:outline hover:outline-2 hover:outline-[#FF671F]"
                }`}
                onClick={() => {
                  setDesign("4x5");
                }}
              >
                <div className="w-fit flex flex-row justify-center items-center gap-3">
                  <Portrait1
                    className={`h-[2rem] w-auto ${
                      design === "4x5" ? "fill-white" : "fill-black"
                    }`}
                  />
                  <h3
                    className={`font-medium text-lg sm:text-xl md:text-xl xl:text-xl text-center ${
                      design === "4x5" ? "text-white" : "text-black"
                    }`}
                  >
                    4x5
                  </h3>
                </div>
              </button>
              <button
                className={`w-full h-14 flex justify-center items-center rounded-2xl ${
                  design === "9x16"
                    ? "bg-[#FF671F]"
                    : "bg-[#EEEEEE] hover:outline hover:outline-2 hover:outline-[#FF671F]"
                }`}
                onClick={() => {
                  setDesign("9x16");
                }}
              >
                <div className="w-fit flex flex-row justify-center items-center gap-3">
                  <Portrait2
                    className={`h-[2rem] w-auto ${
                      design === "9x16" ? "fill-white" : "fill-black"
                    }`}
                  />
                  <h3
                    className={`font-medium text-lg sm:text-xl md:text-xl xl:text-xl text-center ${
                      design === "9x16" ? "text-white" : "text-black"
                    }`}
                  >
                    9x16
                  </h3>
                </div>
              </button>
            </div>
            <hr className="w-full h-[2px] bg-[#00000025] my-5"></hr>
            <div className="w-full gap-5 mb-5">
              <button
                className="w-full h-14 flex justify-center items-center rounded-2xl bg-[#FF671F]"
                onClick={handleDownload}
              >
                <div className="w-fit flex flex-row justify-center items-center gap-3">
                  <DownloadDesign className="h-[2rem] w-auto fill-white" />
                  <h3 className="font-medium text-lg sm:text-xl md:text-xl xl:text-xl text-center text-white">
                    Download & Share
                  </h3>
                </div>
              </button>
            </div>
            <a
              className="text-start font-normal text-sm sm:text-base md:text-base xl:text-base"
              href="https://elevationchurch.org/easter"
            >
              Find a location or streaming time by visiting{" "}
              <span className="font-bold">ElevationChurch.org</span>.
            </a>
          </div>
          {!isMobile && (
            <img
              className={`max-h-[90vh] justify-self-center 2xl:col-span-3 ${
                design === "4x5" ? "aspect-4/5" : "asepct-9/16"
              }`}
              src={`https://storage.googleapis.com/nfc-connect-4addd.appspot.com/easterImages/${campus}/${experience}_${design}.jpg`}
              alt={`${experience}_${design}`}
            />
          )}
        </div>
        <h3 className="text-[#00000050] font-normal text-xs mt-auto pb-8">
          © 2025 Elevation Church.{" "}
          <span className="font-medium">All Rights Reserved.</span>
        </h3>
      </div>
    </div>
  );
}

export default Home;
